<template>
  <v-card
    tile
    elevation="0"
    :class="grid.outer"
  >
    <v-row
      no-gutters
      :justify="settings.justify"
      :style="'min-height: ' + settings.boxHeight + 'px'"
      :align="settings.align"
    >
      <v-col cols="auto">
        <v-card
          tile
          elevation="0"
          :class="grid.inner"
        >
          <img
            :src="settings.image"
            :height="settings.height + 'px'"
          >
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
 export default {
  props: [ 'settings' ],
  computed: {
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    grid() {
      return this.$store.getters.grid
    },
  }
}
</script>

<style scoped>

</style>

