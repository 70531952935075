var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.settings.value)?_c('v-card',{class:_vm.grid.outer,attrs:{"tile":"","elevation":"0"}},[_c('v-row',{style:('min-height: ' + _vm.settings.boxHeight + 'px;' +
      'margin-left: ' + _vm.marginLeftResponsive + 'px;' +
      'margin-right: ' + _vm.marginRightResponsive + 'px;'),attrs:{"no-gutters":"","justify":_vm.settings.justify,"align":_vm.settings.align}},[_c('v-col',{attrs:{"xs":"12","md":_vm.settings.cols}},[_c('v-card',{class:_vm.grid.inner,attrs:{"tile":"","elevation":"0"}},[_c('div',{class:_vm.settings.floatText,style:('font-family: ' + _vm.settings.font + ' !important;'
          + 'color: ' + _vm.settings.color + ';'
          + 'font-size: ' + _vm.settings.size + 'px;'
          + 'font-weight: ' + _vm.settings.weight + ';'
          + 'line-height: ' + _vm.settings.lineHeight + ';'
          + 'font-style: ' + _vm.settings.style + ';')},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":_vm.lines}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }