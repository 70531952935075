<template>
  <div>
    <v-row no-gutters justify="center" align="end" class="pt-7">
      <a href="https://incentable.com/" target="_blank" class="incentable-link">
        <v-img
          :src="require('@/assets/IncentableStar.png')"
          height="38px"
          contain
          class="mb-1"
          style="z-index: 0 !important;"
        />
      </a>
    </v-row>
    <v-row no-gutters justify="center" align="end">
      <a href="https://incentable.com/" target="_blank" class="incentable-link"
        >Website made with Incentable</a
      >
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    }
  }
};
</script>
<style>
.incentable-link {
  font-family: "Roboto", sans-serif !important;
  text-decoration: none !important;
  font-size: 11px !important;
  font-weight: 300 !important;
  color: #bdbdbd !important;
}
</style>
