<template>
  <div>
    <v-dialog
      v-model="termsDialogOpen"
      persistent
      scollable
      :fullscreen="fullscreen"
      max-width="60vw"
      scrollable
    >
      <v-card
        :class="classes"
        :style="styles + ';' + gradient"
        :elevation="component.card.elevation"
      >
        <view-text
          :settings="component.title"
          :value="currentProgram.termsPage.title"
        ></view-text>
        <perfect-scrollbar class="scroll">
          <v-card-text class="px-md-12">
            <div
              v-html="currentProgram.termsPage.content"
              :style="textFormat"
              class="terms-text"
            ></div>
          </v-card-text>
        </perfect-scrollbar>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="onCloseTermsDialog"
            :color="
              this.$store.getters.currentProgram.webTheme.colors.primary.hex
            "
            :dark="!component.button.dark"
            class="mt-9"
            elevation="0"
          >
            {{ $t("CLOSE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ViewText from "../Text.vue";
export default {
  props: ["termsDialogOpen", "onClose", "component"],
  components: {
    ViewText
  },
  data() {
    return {};
  },
  computed: {
    currentProgram() {
      return this.$store.getters.currentProgram;
    },
    webTheme() {
      if (this.currentProgram) {
        return this.$store.getters.currentProgram.webTheme;
      }
      return {};
    },
    gradient() {
      if (this.component.card.color.type === "gradient") {
        var color = this.component.card.color;
        var gradient =
          "background: linear-gradient(" +
          color.direction +
          ", rgba(" +
          color.gradientFrom.rgba.r +
          ", " +
          color.gradientFrom.rgba.g +
          ", " +
          color.gradientFrom.rgba.b +
          ", " +
          color.gradientFrom.rgba.a +
          ") " +
          ", rgba(" +
          color.gradientTo.rgba.r +
          ", " +
          color.gradientTo.rgba.g +
          ", " +
          color.gradientTo.rgba.b +
          ", " +
          color.gradientTo.rgba.a +
          ")" +
          color.gradientHeight +
          "%)";
        return gradient;
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return "background: " + this.component.card.color.solidColor.hexa + ";";
      }
    },
    styles() {
      var styles =
        "margin-bottom: 0px ;" +
        "margin-top: " +
        this.component.card.margin.top +
        "px ;" +
        "margin-left: " +
        this.component.card.margin.left +
        "px ;" +
        "margin-right: " +
        this.component.card.margin.right +
        "px ;" +
        "padding-bottom: 0px ;" +
        "padding-top: " +
        this.component.card.padding.top +
        "px ;" +
        "padding-left: " +
        this.component.card.padding.left +
        "px ;" +
        "padding-right: " +
        this.component.card.padding.right +
        "px ;" +
        "border: " +
        this.component.card.borderSize +
        "px solid " +
        this.component.card.borderColor.hexa +
        "; ";
      return styles;
    },
    classes() {
      var classes = this.component.card.rounded + " pt-16 pb-6";
      return classes;
    },
    textFormat() {
      var styles = "color: " + this.component.signing.color + ";";
      return styles;
    },
    fullscreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    }
  },
  methods: {
    onCloseTermsDialog() {
      this.$emit("onClose");
    }
  }
};
</script>

<style>
.scroll {
  position: relative;
  margin: auto;
  height: auto;
}
.terms-text {
  font-size: 13px !important;
  font-family: "Roboto", sans-serif !important;
}
.ql-indent-1 {
  padding-left: 1.5em;
}
.ql-indent-2 {
  padding-left: 3em;
}
.ql-indent-3 {
  padding-left: 4.5em;
}
.ql-indent-4 {
  padding-left: 6em;
}
.ql-indent-5 {
  padding-left: 7.5em;
}
.ql-indent-6 {
  padding-left: 9em;
}
.ql-indent-7 {
  padding-left: 10.5em;
}
.ql-indent-8 {
  padding-left: 12em;
}
.ql-indent-9 {
  padding-left: 13.5em;
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.ql-editor ol li.ql-indent-1 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-1 {
  counter-increment: list-4;
}
</style>
